'use client'

import { useEffect, useState } from 'react'

export function ChatDate({ createdAt }: { createdAt?: string }) {
  const [formattedDate, setFormattedDate] = useState<string | null>(null)

  useEffect(() => {
    const now: Date = new Date()
    const date: Date = createdAt ? new Date(createdAt) : now

    const options: Intl.DateTimeFormatOptions = {
      hourCycle: 'h24',
      hour: 'numeric',
      minute: 'numeric',
    }

    setFormattedDate(date.toLocaleTimeString('en-US', options))
  }, [createdAt])

  return <span className="text-xs">{formattedDate}</span>
}
