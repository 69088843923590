import z from 'zod'

export const STARTER_SENSAY_PLAN = 'starter'
export const PROFESSIONAL_SENSAY_PLAN = 'professional'
export const ENTERPRISE_SENSAY_PLAN = 'enterprise'
export const FREE_SENSAY_PLAN = 'free'

export const frequencies = ['month', 'year'] as const

export const SALES_EMAIL = 'mailto:sales@sensay.io'

export type Frequency = (typeof frequencies)[number]

export const MAX_FREE_CHAT_MESSAGES = 6
export const SIGNED_IN_MAX_FREE_CHAT_MESSAGES = 50

export function isCurrentPlan({
  currentPlan,
  plan,
  planFrequency,
  userPlanFrequency,
}: {
  currentPlan: SensayPlan | ''
  plan: SensayPlan
  planFrequency: Frequency
  userPlanFrequency: Frequency | undefined | ''
}) {
  if (currentPlan === FREE_SENSAY_PLAN && plan === currentPlan) return true

  return currentPlan === plan && planFrequency === userPlanFrequency
}

export const FrequencyStrictSchema = z.enum(['month', 'year'])
export const FrequencySchema = z.enum(['month', 'year', ''])

export const stripeProductsToPlans: Record<string, SensayPlan> = {
  // production
  prod_QlPZ4ohqwMW9rn: STARTER_SENSAY_PLAN,
  prod_QlPZUFSLJvrxxt: PROFESSIONAL_SENSAY_PLAN,

  // legacy
  prod_QBRSGY2JeSilpf: PROFESSIONAL_SENSAY_PLAN,
  prod_QBRSQTPtA3lGAB: PROFESSIONAL_SENSAY_PLAN, // ok with flowtrade customer was Pro Voice
  prod_QBRS8cQyQzabH2: PROFESSIONAL_SENSAY_PLAN,
  prod_QBRSCbJOGwaL4w: PROFESSIONAL_SENSAY_PLAN, // ok with pictographs.io and arqexchange.io customer was Pro
  prod_Oty5PkV1VwZWVQ: PROFESSIONAL_SENSAY_PLAN, // ok with customers was Your Replica
  prod_OjBjFU7AgDsTo6: STARTER_SENSAY_PLAN, // ok with customers was premium

  // test env
  prod_QlLS8bxspfGx5o: STARTER_SENSAY_PLAN,
  prod_QlLUup7wieRVwm: PROFESSIONAL_SENSAY_PLAN,
}

export type SensayPlan = z.infer<typeof SensayPlanSchema>

const sensayPlans = [FREE_SENSAY_PLAN, STARTER_SENSAY_PLAN, PROFESSIONAL_SENSAY_PLAN, ENTERPRISE_SENSAY_PLAN] as const
export const SensayPlanSchema = z.enum(sensayPlans)

export const validProPlans: SensayPlan[] = [PROFESSIONAL_SENSAY_PLAN, ENTERPRISE_SENSAY_PLAN]

export function getHigherPlan(plan: SensayPlan): SensayPlan | undefined {
  switch (plan) {
    case FREE_SENSAY_PLAN:
      return STARTER_SENSAY_PLAN
    case STARTER_SENSAY_PLAN:
      return PROFESSIONAL_SENSAY_PLAN
    case PROFESSIONAL_SENSAY_PLAN:
      return ENTERPRISE_SENSAY_PLAN
    case ENTERPRISE_SENSAY_PLAN:
      return undefined
  }
}

// const COMMUNITY_TELEGRAM_LINK = 'https://t.me/asksensay'

export type StripePlan = {
  plan: SensayPlan
  title: string
  iconPath: string
  detail: string
  textColor: string
  bgColor: string
  features: string[]
  featuresTitle: string
  month: {
    yearlyDiscount?: string
    price: string
    liveId: string
    testId: string
  }
  year: {
    yearlyDiscount?: string
    price: string
    liveId: string
    testId: string
  }
}

export const stripePlans: StripePlan[] = [
  {
    plan: FREE_SENSAY_PLAN,
    title: 'Free',
    iconPath: '/assets/pricing/free-icon.svg',
    detail: 'Start exploring Sensay for free',
    featuresTitle: 'What You Get:',
    features: [
      '1 Public Replica',
      `${SIGNED_IN_MAX_FREE_CHAT_MESSAGES} messages/day`,
      '25MB Data Storage',
      'Training Assistant',
      'Data Encryption',
      'Community Access',
      '100+ Languages',
      'Earn with Referrals',
    ],
    textColor: 'text-emerald-500',
    bgColor: 'bg-emerald-500',
    month: {
      price: '0',
      liveId: '',
      testId: '',
    },
    year: {
      price: '0',
      liveId: '',
      testId: '',
    },
  },
  {
    plan: STARTER_SENSAY_PLAN,
    title: 'Starter',
    iconPath: '/assets/pricing/start-icon.svg',
    detail: 'Create more replicas with enhanced training',
    textColor: 'text-blue-500',
    bgColor: 'bg-blue-500',
    featuresTitle: 'All Free Features, plus:',
    features: [
      '10 Replicas',
      '1,000 messages/day',
      '100MB Data Storage',
      'Private Replicas',
      'Train with Files & Links',
      // 'Monetize your replica',
      'Quests to Earn Tokens',
      'Training Courses',
    ],
    month: {
      yearlyDiscount: '$150',
      price: '45',
      liveId: 'price_1Q3bMXKuls4YqbSx4pg04do9',
      testId: 'price_1Q3dbMKuls4YqbSxLGlvEY7x',
    },
    year: {
      yearlyDiscount: '$150',
      price: '30',
      liveId: 'price_1Q3c5sKuls4YqbSxrDEgN9pQ',
      testId: 'price_1Q3db2Kuls4YqbSxedEnaPla',
    },
  },
  {
    plan: PROFESSIONAL_SENSAY_PLAN,
    title: 'Professional',
    textColor: 'text-primary',
    bgColor: 'bg-primary',
    iconPath: '/assets/pricing/professional-icon.svg',
    detail: 'Automate and scale your AI workforce',
    featuresTitle: 'All Starter Features, plus:',
    features: [
      'Unlimited Replicas',
      '5,000 messages/day',
      '1GB Data Storage',
      'Automated Workflows',
      'Custom Personality',
      'Accuracy Tools',
      'Discord Copilot',
      'Telegram Copilot',
      'Voice Interactions',
      'Website widget',
    ],
    month: {
      price: '225',
      yearlyDiscount: '$900',
      liveId: 'price_1Ptsk3Kuls4YqbSx8P0hp3gP',
      testId: 'price_1PtonOKuls4YqbSx9NIvXbVp',
    },
    year: {
      price: '150',
      yearlyDiscount: '$900',
      liveId: 'price_1Ptsk3Kuls4YqbSx5zUX9BnD',
      testId: 'price_1PtonOKuls4YqbSx399PRwE8',
    },
  },
  {
    plan: ENTERPRISE_SENSAY_PLAN,
    title: 'Enterprise',
    iconPath: '/assets/pricing/enterprise-icon.svg',
    detail: 'Custom AI solutions for enterprise scalability',
    featuresTitle: 'All Pro Features, plus:',
    textColor: 'text-violet-500',
    bgColor: 'bg-violet-500',
    features: [
      'Custom Replicas',
      'Unlimited Messaging',
      'Custom Data Storage',
      'Custom Integrations',
      'Full Automation',
      'Dedicated Support',
      'API Access',
    ],
    month: {
      price: 'Let’s Talk',
      liveId: '',
      testId: '',
    },
    year: {
      price: 'Let’s Talk',
      liveId: '',
      testId: '',
    },
  },
]

export type Features = {
  [feature: string]: PlanDetails
}

export type PlanDetails = {
  tooltip: string
  link?: string
  hidden?: boolean
} & Record<SensayPlan, boolean | string | number>

export const featureCategories = {
  Replicas: {
    Replicas: {
      [FREE_SENSAY_PLAN]: 1,
      [STARTER_SENSAY_PLAN]: 3,
      [PROFESSIONAL_SENSAY_PLAN]: 10,
      [ENTERPRISE_SENSAY_PLAN]: Number.POSITIVE_INFINITY,
      tooltip: 'Digital assistants that can interact with anyone',
    },
    'Private Replicas': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Digital assistants accessible only to you or selected users',
    },
  },
  'Messaging & Storage': {
    'Messages per Day': {
      [FREE_SENSAY_PLAN]: '50',
      [STARTER_SENSAY_PLAN]: '1,000',
      [PROFESSIONAL_SENSAY_PLAN]: '5,000',
      [ENTERPRISE_SENSAY_PLAN]: 'Unlimited',
      tooltip: 'Number of interactions your replicas can have daily',
    },
    'Data Storage': {
      [FREE_SENSAY_PLAN]: '25 MB',
      [STARTER_SENSAY_PLAN]: '100 MB',
      [PROFESSIONAL_SENSAY_PLAN]: '1 GB',
      [ENTERPRISE_SENSAY_PLAN]: 'Custom Storage',
      tooltip: 'Amount of information your replicas can remember',
    },
    '100+ Languages': {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Your replicas can communicate in over 100 languages',
    },
  },
  'Training & Interaction': {
    'Training Assistant': {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Guided tool to help you teach your replica',
    },
    'Train with Files & Links': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Teach your replica using documents and web pages',
    },
    'Voice Interactions': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Speak with your replica using voice commands',
    },
    'Import ElevenLabs API key': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Use your own ElevenLabs API key to add a custom voice to your replica',
      hidden: true,
    },
    'Custom Personality': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Tailor your replica's behavior and traits",
    },
    'Discord Copilot': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Use your replica on Discord for community management',
    },
    'Telegram Copilot': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Deploy your replica on Telegram for messaging',
    },
  },
  // "Monetize Your Replica": {
  //   "Earn with Referrals": {
  //     [FREE_SENSAY_PLAN]: true,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Get rewards when others sign up using your link",
  //   },
  //   "Monetize Your Replica": {
  //     [FREE_SENSAY_PLAN]: false,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Earn money from your replica's interactions",
  //   },
  //   "Quests to Earn Tokens": {
  //     [FREE_SENSAY_PLAN]: false,
  //     [STARTER_SENSAY_PLAN]: true,
  //     [PROFESSIONAL_SENSAY_PLAN]: true,
  //     [ENTERPRISE_SENSAY_PLAN]: true,
  //     tooltip: "Complete tasks to earn $SNSY tokens",
  //   },
  // },
  'Automation & Customization': {
    'Automated Workflows': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Set up your replica to perform tasks automatically',
    },
    'Full Automation': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Create complex, fully automated processes with your replicas',
    },
  },
  'Support & Security': {
    'Community Access': {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Join our user community for support and collaboration',
    },
    'Dedicated Support': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Get personalized help from our support team',
    },
    'API Access': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Integrate Sensay features into your own applications',
    },
    'Data Encryption': {
      [FREE_SENSAY_PLAN]: true,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Keep your replica's data secure and private",
    },
    'Accuracy Tools': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: "Advanced features to improve your replica's responses",
    },
    'Custom Storage': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: false,
      [PROFESSIONAL_SENSAY_PLAN]: false,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Tailored data storage solutions for your needs',
    },
    'Training Courses': {
      [FREE_SENSAY_PLAN]: false,
      [STARTER_SENSAY_PLAN]: true,
      [PROFESSIONAL_SENSAY_PLAN]: true,
      [ENTERPRISE_SENSAY_PLAN]: true,
      tooltip: 'Access educational resources to maximize your use of replicas',
    },
  },
} as const

export type FeatureCategory = keyof typeof featureCategories
