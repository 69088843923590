import { ArrowRight } from '@phosphor-icons/react'
import Link from 'next/link'

function UserFreeTierExpired() {
  return (
    <div className="flex flex-col items-center gap-6 text-lg">
      <p className="text-center">
        Upgrade your experience <br /> Subscribe and chat with personality-rich replicas!
      </p>
      <Link
        href="/pricing/year"
        className="flex cursor-pointer flex-row items-center gap-3 rounded-[100px] border-2 border-primary px-4 py-2 font-semibold text-primary duration-300 hover:bg-primary/10"
      >
        <div className="flex items-center">
          <p className="bg-transparent font-normal text-primary">Check out pricing plans!</p>

          <div className="ml-2 flex h-6 w-6 items-center justify-center rounded-full bg-primary text-white">
            <ArrowRight weight="bold" size={16} />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default UserFreeTierExpired
