import Spinner from '@/components/Spinner'
import type { ServerActionError } from '@/server-actions/ServerActionsError'
import { evaluateScore, getCarbonFileChunk } from '@/server-actions/carbonAI'
import { XCircle } from '@phosphor-icons/react'
import * as Popover from '@radix-ui/react-popover'
import { useQuery } from '@tanstack/react-query'
import { type ReactNode, useState } from 'react'
import InlineChunkReference from './InlineChunkReference'

const CHUNK_REGEX = /load-chunk-(\d+)-(\d+)/

function ChunkReference({
  className,
  href,
  personaId,
  content,
  previousContent,
  isOwner,
  children,
  ...props
}: {
  href: string
  className?: string
  personaId: string

  content: string
  previousContent?: string
  isOwner: boolean
  children: ReactNode
}) {
  const [enabled, setEnabled] = useState(false)
  const [, fileId, chunkIndex] = href.match(CHUNK_REGEX) || []

  const { data, isLoading } = useQuery({
    queryKey: ['chunk-reference', personaId, fileId, chunkIndex],
    queryFn: async ({ queryKey: [, personaId, fileId, chunkIndex] }) => {
      try {
        const { chunk, source } = await getCarbonFileChunk(
          personaId,
          Number.parseInt(fileId),
          Number.parseInt(chunkIndex),
        )
        const score = await evaluateScore(
          Number.parseInt(fileId),
          Number.parseInt(chunkIndex),
          `${previousContent}\n${content}`,
          chunk,
        )
        return {
          chunk,
          score,
          source,
        }
      } catch (error: unknown) {
        return {
          error: error as ServerActionError,
        }
      }
    },
    enabled,
  })

  return (
    <Popover.Root open={enabled} onOpenChange={setEnabled}>
      <Popover.Trigger asChild>
        <button
          className={className}
          onClick={() => {
            setEnabled((oldEnabled) => !oldEnabled)
          }}
          {...props}
        >
          {children}
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="max-w-[--radix-popper-available-width] px-5">
          <div
            className={`${data?.error ? 'bg-red-100' : 'bg-white p-4'} rounded-md shadow-md whitespace-pre-wrap max-h-60 overflow-y-auto min-w-80`}
          >
            {isLoading && <Spinner className="justify-center" />}
            <Popover.Close className={`absolute top-2 ${isLoading ? 'right-8' : 'right-10'}`}>
              <XCircle size={24} />
            </Popover.Close>
            {data && <InlineChunkReference isOwner={isOwner} {...data} />}
          </div>
          <Popover.Arrow className="fill-white w-5 h-3" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

const CHUNK_REF_CLASSNAME =
  'font-bold text-primary underline underline-offset-2 transition-opacity duration-300 hover:opacity-70'

export function ChunkReferenceLink({
  href,
  children,
  personaId,
  content,
  previousContent,
  isOwner,
  ...props
}: {
  href?: string
  children: ReactNode
  personaId: string
  content: string
  previousContent?: string
  isOwner: boolean
}) {
  return href?.includes('#load-chunk-') ? (
    <ChunkReference
      href={href}
      className={CHUNK_REF_CLASSNAME}
      personaId={personaId}
      content={content}
      previousContent={previousContent}
      isOwner={isOwner}
      {...props}
    >
      {children}
    </ChunkReference>
  ) : (
    <a href={href} target="_blank" className={CHUNK_REF_CLASSNAME} rel="noreferrer" {...props}>
      {children}
    </a>
  )
}
