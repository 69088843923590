import { ArrowsCounterClockwise } from '@phosphor-icons/react'
import { twMerge } from 'tailwind-merge'
import UserChatSuggestion from './UserChatSuggestion'
export interface ChatSuggestionsProps {
  isNewChat: boolean
  suggestedQuestions: string[]
  setSuggestedQuestion: (question: string) => void
  getSuggestionQuestions: () => void
  className?: string
  persona: string
}

export function ChatSuggestions({
  isNewChat,
  suggestedQuestions = [],
  setSuggestedQuestion,
  getSuggestionQuestions,
  className,
  persona,
}: ChatSuggestionsProps) {
  if (!isNewChat) return null
  if (!suggestedQuestions.length) return null

  return (
    <div className={twMerge('flex flex-col items-end gap-3', className)}>
      <div className="flex items-center">
        <h6 className="mr-[10px] select-none">Suggested questions</h6>
        <ArrowsCounterClockwise
          color="#FE7439"
          className="cursor-pointer"
          onClick={getSuggestionQuestions}
          weight="bold"
          height={18}
          width={18}
        />
      </div>
      {suggestedQuestions.map((question: string, index: number) => (
        <button
          className="w-fit select-none rounded-full rounded-br-lg border border-primary/30 bg-primary/10 px-3 py-1 text-right text-[#331c12] shadow-md transition-transform duration-300 last:mb-2 hover:-translate-x-2"
          onClick={(e) => {
            e.preventDefault()
            setSuggestedQuestion(question)
          }}
          type="submit"
          key={index}
        >
          {question}
        </button>
      ))}
      <UserChatSuggestion persona={persona} />
    </div>
  )
}
