import PrimaryButton from '@/components/PrimaryButton'
import { ArrowRight } from '@phosphor-icons/react'
import { signIn } from 'next-auth/react'

function FreeTierExpired() {
  return (
    <div className="mt-8 flex flex-col items-center gap-6 text-lg">
      <div>
        <h3 className="mb-[16px] text-center text-xl">Upgrade your experience!</h3>
        <p className="text-center">Sign up or log in now for more exclusive features and personalized content.</p>
      </div>
      <PrimaryButton
        onClick={() => signIn()}
        className="flex cursor-pointer flex-row items-center rounded-[100px] border border-primary px-4 py-2 duration-300 hover:bg-primary/10"
      >
        <div className="flex items-center">
          <p className="bg-transparent text-base font-medium text-primary">Create free account</p>

          <div className="ml-2 flex h-6 w-6 items-center justify-center rounded-full bg-primary text-white">
            <ArrowRight weight="bold" size={16} />
          </div>
        </div>
      </PrimaryButton>
    </div>
  )
}

export default FreeTierExpired
