'use client'
import ErrorBox from '@/components/ErrorBox'
import { useEffect, useState } from 'react'
function OfflineErrorBox() {
  const [isOnline, setIsOnline] = useState<boolean>(true)

  useEffect(() => {
    // Update network status
    setIsOnline(navigator?.onLine)
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine)
    }

    // Listen to the online status
    window.addEventListener('online', handleStatusChange)

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange)

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [isOnline])
  return (
    <ErrorBox error={!isOnline}>
      Internet connection unavailable.
      <br /> Please connect to the internet and try again{' '}
    </ErrorBox>
  )
}

export default OfflineErrorBox
