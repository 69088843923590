import { captureException } from '@sentry/browser'
import { ZodError } from 'zod'

export class ServerActionError extends Error {
  sentryId: string

  constructor(friendlyMessage: string, error: unknown, sentryId: string) {
    super(friendlyMessage)
    this.name = this.constructor.name
    this.cause = error
    this.sentryId = sentryId
    this.stack = error instanceof Error ? error.stack : ''
  }
}

export function wrapServerActionError(error: unknown) {
  let displayError = 'An unexpected error occurred.'

  if (error instanceof ZodError) {
    displayError = error.errors.map((zodError) => `${zodError.path.join('.')}: ${zodError.message}`).join('\n')
  } else if (error instanceof Error) {
    displayError = error.message
  } else if (typeof error === 'string') {
    displayError = error
  }

  const sentryId = captureException(error)
  return new ServerActionError(displayError, error, sentryId)
}
