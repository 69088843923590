import { CheckCircle, Info, Warning, X, XCircle } from '@phosphor-icons/react/dist/ssr'
import Link from 'next/link'
import type { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface AlertProps {
  heading: string
  children?: ReactNode
  type?: 'success' | 'info' | 'warning' | 'error'
  parentClassName?: string
  headingClassName?: string
  hideLinkBtn?: boolean
  linkBtn?:
    | {
        text: string
        href: string
      }
    | false
  actionBtn?:
    | {
        text: string
        handleActionBtn: () => void
      }
    | false
  hideButtons?: boolean
  openLinkButtonInNewTab?: boolean
  onClose?: () => void
}

const COLORS = {
  success: {
    background: 'bg-green-100',
    icon: 'text-green-500',
    heading: 'text-green-900',
    text: 'text-green-900',
    border: 'border-green-400',
  },
  info: {
    background: 'bg-blue-50',
    icon: 'text-blue-300',
    heading: 'text-blue-900',
    text: 'text-blue-900',
    border: 'border-blue-200',
  },
  warning: {
    background: 'bg-yellow-50',
    icon: 'text-yellow-300',
    heading: 'text-yellow-900',
    text: 'text-yellow-900',
    border: 'border-yellow-200',
  },
  error: {
    background: 'bg-[#ff4e4a1a]',
    icon: 'text-[#ff4e4a]',
    heading: 'text-[#ff4e4a]',
    text: 'text-[#1f2225]',
    border: 'border-[#ff4e4a1a]',
  },
}

const ICON_COMPONENTS = {
  success: CheckCircle,
  info: Info,
  warning: Warning,
  error: XCircle,
}

export function Alert({
  heading,
  children,
  type = 'error',
  parentClassName = '',
  headingClassName = '',
  linkBtn = false,
  actionBtn = false,
  hideLinkBtn = false,
  hideButtons = false,
  openLinkButtonInNewTab = true,
  onClose,
}: AlertProps) {
  const Icon = ICON_COMPONENTS[type]
  const colors = COLORS[type]

  if (type === 'error' && !linkBtn && !hideLinkBtn)
    linkBtn = {
      text: 'Send feedback',
      href: 'https://sensay.typeform.com/to/dMevqrgs',
    }

  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 p-10 items-start self-stretch rounded-xl border relative',
        colors.background,
        colors.border,
        parentClassName,
      )}
    >
      {onClose && <X className="absolute top-4 right-4 cursor-pointer" size={20} onClick={onClose} />}
      <div className="flex md:items-center gap-2 w-full">
        <span className="max-md:mt-1">
          <Icon weight="fill" className={twMerge('h-6 w-6', colors.icon)} aria-hidden="true" />
        </span>
        {heading && (
          <span className={twMerge('font-medium text-xl leading-8', colors.heading, headingClassName)}>{heading}</span>
        )}
      </div>

      <div className={twMerge('max-w-full text-lg leading-7 overflow-x-auto', colors.text)}>{children}</div>

      {(linkBtn || actionBtn) && !hideButtons && (
        <div className="mt-4 md:mt-0 flex text-nowrap items-center ml-auto">
          {actionBtn && (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                actionBtn.handleActionBtn()
              }}
              className="px-6 py-2 mr-2 rounded-full bg-white/0 text-foreground duration-300 hover:bg-white/60 cursor-pointer"
            >
              {actionBtn.text}
            </button>
          )}

          {linkBtn && (
            <Link
              href={linkBtn.href}
              target={openLinkButtonInNewTab ? '_blank' : '_self'}
              rel="noopener noreferrer"
              className="px-6 py-2 rounded-full bg-zinc-950 text-white duration-300 hover:bg-zinc-950/80 cursor-pointer"
            >
              {linkBtn.text}
            </Link>
          )}
        </div>
      )}
    </div>
  )
}
