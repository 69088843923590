'use client'

import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { useAtBottom } from '@/hooks/useAtBottom'

interface ChatScrollAnchorProps {
  trackVisibility?: boolean
  hasMessages: boolean
  lastMessage?: string
}

const useChatScrollAnchor = ({ trackVisibility, lastMessage, hasMessages }: ChatScrollAnchorProps) => {
  const isAtBottom = useAtBottom()

  const { ref, entry, inView } = useInView({
    trackVisibility,
    delay: 100,
    rootMargin: '0px 0px -150px 0px',
  })

  const [firstLoadScroll, setFirstLoadBottom] = useState<boolean>(false)

  const scrollToBottom = () => {
    entry?.target.scrollIntoView({
      block: 'start',
    })
  }

  useEffect(() => {
    if (isAtBottom && !inView) {
      entry?.target.scrollIntoView({
        block: 'start',
      })
    }
  }, [inView, entry, isAtBottom, trackVisibility, lastMessage])

  useEffect(() => {
    // The interval scrolls to the div until the page is scrolled
    // to the bottom. Once it reaches the bottom it makes the firstLoadBottom
    // state to true in order to disable the useEffect.
    // Runs for the first load page so we don't begin at the top of the chat history.
    if (!hasMessages) return
    if (firstLoadScroll) return

    entry?.target.scrollIntoView({
      block: 'start',
    })

    // scroll again after 1 second to make sure it's at the bottom
    const scrollInterval = setInterval(() => {
      entry?.target.scrollIntoView({
        block: 'start',
      })

      setFirstLoadBottom(true)
    }, 1000)

    return () => clearInterval(scrollInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, isAtBottom, hasMessages])

  return { scrollLine: <div ref={ref} className="h-px w-full" />, scrollToBottom }
}

export default useChatScrollAnchor
