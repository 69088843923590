import { XCircle } from '@phosphor-icons/react'
import type { ReactNode } from 'react'

export default function ErrorBox({ children, error }: { children: ReactNode; error: boolean }) {
  return (
    <div
      className={` absolute right-10 top-24  z-20  max-w-[350px] rounded-md  max-sm:left-1/2 max-sm:w-[95%] max-sm:-translate-x-1/2  sm:mx-0 ${
        error ? null : 'hidden'
      }  bg-black/80 p-4 transition-all`}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircle className="h-5 w-5 text-secondary" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-secondary">{children}</h3>
        </div>
      </div>
    </div>
  )
}
