
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"132b4752c80834a0af8e7993a0eea80d6bb5dae2":"$$ACTION_0","48d5a23eae291f1172b7011ccae7c2f30614ae5a":"getTrainingAnswerSuggestions","6a08372c329b96639db026e4e75df3328fc49a35":"$$ACTION_2","85e3bb2f881efdf9cc3d44db20937cd9ff92bd58":"evaluateScore","9eecba837af7e6afe200aff0f3af66c7467f5401":"getCarbonFileChunk","da5a25105835cbb0fb565a41cee5333cfcc04a81":"$$ACTION_1"} */ export var evaluateScore = createServerReference("85e3bb2f881efdf9cc3d44db20937cd9ff92bd58");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getCarbonFileChunk = createServerReference("9eecba837af7e6afe200aff0f3af66c7467f5401");
export var getTrainingAnswerSuggestions = createServerReference("48d5a23eae291f1172b7011ccae7c2f30614ae5a");

