
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"2256c83e8b949bbf8a686881342735361fc2cc4f":"getReplicaHeygenApiKeyAndAvatarId","3b0f1c22699485ba195a97fc94b79ee0fc7e10ae":"updateHeygenApiKey","4902792ab3757bdb9051b638647066fc53b58f37":"getIsReplicaInteractiveAvatarEnabledServerAction","e866752fd428518e0377f3946b77ef0f4f1cc9f5":"deleteHeygenData"} */ export var getIsReplicaInteractiveAvatarEnabledServerAction = createServerReference("4902792ab3757bdb9051b638647066fc53b58f37");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var updateHeygenApiKey = createServerReference("3b0f1c22699485ba195a97fc94b79ee0fc7e10ae");
export var deleteHeygenData = createServerReference("e866752fd428518e0377f3946b77ef0f4f1cc9f5");
export var getReplicaHeygenApiKeyAndAvatarId = createServerReference("2256c83e8b949bbf8a686881342735361fc2cc4f");

