import { CircleNotch } from '@phosphor-icons/react'
import type { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

const LoadingState = ({
  text,
  className,
  size,
  color,
}: { text?: ReactNode; className?: string; size?: number; color?: string }) => {
  return (
    <div className={twMerge('flex items-center justify-end gap-1', className)}>
      <CircleNotch
        color={color}
        className={twMerge('w-fit  shrink-0 animate-spin', !size && 'max-h-4 ')}
        size={size ? size : 30}
      />
      {text ? <span>{text}</span> : null}
    </div>
  )
}

export default LoadingState
