import { memo, useState } from 'react'

import { ArrowLeft, ArrowRight, X } from '@phosphor-icons/react'
import Image from 'next/image'
import { Carousel } from 'react-responsive-carousel'

const ChatImages = memo(({ images }: { images?: string[] }) => {
  const [selectedImage, setSelectedImage] = useState<number>(0)
  const [openSlider, setOpenSlider] = useState<boolean>(false)

  if (!images || images.length <= 0) return <></>

  const GridImage = ({ image, index }: { image: string; index: number }) => {
    const [loading, setLoading] = useState<boolean>(true)

    return (
      <div
        className="relative h-56 w-full cursor-pointer overflow-hidden rounded-md border border-white shadow"
        key={index}
      >
        {loading && (
          <div className="absolute z-10 h-full w-full overflow-hidden rounded-md border border-white bg-white" />
        )}

        <Image
          onClick={() => setSelectedImage(index)}
          alt="athena_image"
          src={image}
          className="z-0 object-cover transition hover:scale-105"
          onLoad={() => setLoading(false)}
          fill
        />
      </div>
    )
  }

  const GridHandler = () => {
    if (images.length <= 2 || images?.length === 4)
      return (
        <div className="grid grid-cols-2 gap-2" onClick={() => setOpenSlider(true)}>
          {images
            .filter((image) => image.startsWith('http') || image.startsWith('blob'))
            .map((image, index) => (
              <GridImage image={image} index={index} key={index} />
            ))}
        </div>
      )

    if (images?.length === 3)
      return (
        <div className="grid grid-cols-3 gap-2" onClick={() => setOpenSlider(true)}>
          {images
            .filter((image) => image.startsWith('http') || image.startsWith('blob'))
            .map((image, index) => (
              <GridImage image={image} index={index} key={index} />
            ))}
        </div>
      )

    if (images?.length >= 5)
      return (
        <div>
          <div className="mb-2 grid grid-cols-2 gap-2" onClick={() => setOpenSlider(true)}>
            {images
              .filter((image) => image.startsWith('http') || image.startsWith('blob'))
              .slice(0, 2)
              .map((image, index) => (
                <GridImage image={image} index={index} key={index} />
              ))}
          </div>

          <div className="grid grid-cols-3 gap-2" onClick={() => setOpenSlider(true)}>
            {images
              .filter((image) => image.startsWith('http') || image.startsWith('blob'))
              .slice(2, 5)
              .map((image, index) => (
                <GridImage image={image} index={index} key={index} />
              ))}
          </div>
        </div>
      )

    return <div />
  }

  return (
    <div className="grid max-w-[400px]">
      {openSlider && (
        <div>
          <div
            onClick={() => setOpenSlider(false)}
            className="fixed bottom-0 left-0 right-0 top-0 z-[99] bg-black/60"
          />

          <div className="fixed left-1/2 top-1/2 z-[100] w-full -translate-x-1/2 -translate-y-1/2 transform rounded-lg md:w-[600px] ">
            <Carousel
              infiniteLoop
              swipeable
              showThumbs={false}
              emulateTouch
              thumbWidth={100}
              transitionTime={300}
              swipeScrollTolerance={5}
              showStatus={false}
              dynamicHeight
              selectedItem={selectedImage}
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <ArrowLeft
                    onClick={onClickHandler}
                    className="absolute left-2 top-1/2 z-20 hidden h-7 w-7 -translate-y-1/2 transform cursor-pointer rounded-full bg-black/20 p-1 text-white duration-150 hover:text-primary md:block"
                  />
                )
              }
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <ArrowRight
                    onClick={onClickHandler}
                    className="absolute right-2 top-1/2 z-20  hidden h-7 w-7 -translate-y-1/2 transform cursor-pointer rounded-full bg-black/20 p-1 text-white duration-150 hover:text-primary md:block"
                  />
                )
              }
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className="relative mx-auto flex h-[400px] w-[98%] select-none md:h-[600px] md:w-[600px]"
                >
                  <Image alt="athena_image" quality={100} fill src={image} className="object-contain" />
                </div>
              ))}
            </Carousel>
            <X
              onClick={() => setOpenSlider(false)}
              className="absolute right-1 top-1 z-30 h-8 w-8 rounded-full bg-black/10 p-2 text-white md:hidden"
            />
          </div>
        </div>
      )}
      <GridHandler />
    </div>
  )
})

ChatImages.displayName = 'ChatImages'
export default ChatImages
