'use client'

import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'

import { useChat } from 'ai/react'
import type { Session } from 'next-auth'

import { trackEvent } from '@/lib/trackEvent'
import { ChatSuggestions } from './ChatSuggestions'
import FreeTierExpired from './FreeTierExpired'
import UserFreeTierExpired from './UserFreeTierExpired'

import LoadingState from '@/components/LoadingState'
import ChatHistory from './ChatHistory'
import PersonaTypingIndicator from './PersonaTypingIndicator'

import ChatError from '@/app/[replicaId]/ChatError'
import useChatReplicaError from '@/app/[replicaId]/useChatReplicaError'
import { MAX_FREE_CHAT_MESSAGES, SIGNED_IN_MAX_FREE_CHAT_MESSAGES } from '@/app/pricing/[[...slugs]]/stripe-plans'
import { isVoiceEnabled } from '@/app/studio/[replicaId]/voice/is-voice-enabled'
import { Alert } from '@/components/Alert'
import type { ChatHistoryRows, Persona, User } from '@/lib/types/supabase'
import { getIsReplicaInteractiveAvatarEnabledServerAction } from '@/server-actions/video'
import { useQuery } from '@tanstack/react-query'
import type { Message } from 'ai'
import { signIn } from 'next-auth/react'
import { twMerge } from 'tailwind-merge'
import ChatInput from './ChatInput'
import useChatScrollAnchor from './useScrollAnchor'

export type HistoryMessage = {
  id: string
  name: string
  role: Message['role']
  profileUrl: string
  content: string
  context?: string
  voiceEnabled: boolean
  createdAt: Date
  is_liked: boolean
  images?: string[]
  like_id: string | null
  memory: {
    memory: string | null
    id: string | null
    liked: null
    pair_message: { content: string }
  }
}

export interface ChatProps {
  favourited: boolean
  session: Session | null
  v?: string
  user?: User | null
  persona: Persona
  inputPrompt?: string
  userName?: string | null
  messageCap: boolean
  history?: ChatHistoryRows
  ignoreSubscriptions?: boolean
  embed?: boolean
  className?: string
  classNameInput?: string
  primaryBgColor?: string
  skipAuth?: boolean
}

function structureImageMessages(messageImages: (string | Blob | MediaSource)[], fromSupabase: boolean) {
  const imageMessages: string[] = []

  messageImages.map((image: string | Blob | MediaSource) => {
    imageMessages.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image.length > 40
        ? image
        : fromSupabase
          ? `${process.env.NEXT_PUBLIC_STORAGE_PATH}/athena_images/${image}`
          : URL.createObjectURL(image as Blob | MediaSource),
    )
  })

  return imageMessages
}

function isAnonymousTierExpired(
  session: Session | null,
  chatHistoryLength: number,
  ignoreSubscriptions: boolean,
  embed: boolean,
) {
  if (ignoreSubscriptions) {
    return false
  }

  const isAnonymousUser = !session
  const hasReachedFreeLimit = chatHistoryLength >= MAX_FREE_CHAT_MESSAGES
  const isNotEmbedded = !embed

  return isAnonymousUser && hasReachedFreeLimit && isNotEmbedded
}

const Chat = ({
  favourited,
  session,
  user,
  persona,
  inputPrompt,
  userName,
  messageCap,
  history = [],
  ignoreSubscriptions = false,
  embed = false,
  className = '',
  classNameInput = '',
  primaryBgColor,
  skipAuth = false,
}: ChatProps) => {
  const { error, setError, resetError } = useChatReplicaError()
  const username = user ? (user.name ? user.name : (user.email ?? '')) : (userName ?? 'Anonymous')

  const replicaVoiceEnabled = isVoiceEnabled(persona.voice_manager, persona.elevenlabsId, persona.el_custom_voice_id)

  const defaultChatHistory = useMemo(
    () =>
      history.map((message) => {
        const name = message.role === 'assistant' ? persona.name : username
        let profileUrl = ''

        if (message.role === 'assistant') {
          profileUrl = persona.profile_image
        } else if (user?.profile_image) {
          profileUrl = user.profile_image
        }

        const elevenlabsId = message.role === 'assistant' ? persona.elevenlabsId : ''
        const voiceManager = message.role === 'assistant' ? persona.voice_manager : 'none'
        const userElevenlabsId = message.role === 'assistant' ? persona.el_custom_voice_id : ''

        if (message.images) {
          const imageMessages = structureImageMessages(message.images, true)

          message.images = imageMessages
        }

        return {
          id: String(message.id),
          name,
          role: message.role,
          profileUrl,
          content: message.content,
          elevenlabsId,
          voiceManager,
          userElevenlabsId,
          createdAt: new Date(message.created_at),
          is_liked: message.is_liked,
          images: undefined,
          like_id: message.like_id,
          // @ts-ignore
          memory: message?.memories?.[0] ?? null,
          voiceEnabled: message.role === 'assistant' && replicaVoiceEnabled,
        } as HistoryMessage
      }),
    [
      history,
      persona.name,
      persona.elevenlabsId,
      persona.voice_manager,
      persona.el_custom_voice_id,
      persona.id,
      persona.profile_image,
      username,
      user?.profile_image,
      replicaVoiceEnabled,
    ],
  )

  const chatHistoryRef = useRef<HistoryMessage[]>(defaultChatHistory)

  const getRandomSuggestionQuestions = useCallback(() => {
    if (!persona.suggested_questions || !Array.isArray(persona.suggested_questions)) return []

    const shuffledQuestions = [...persona.suggested_questions].sort(() => Math.random() - 0.5)
    const firstThreeQuestions = shuffledQuestions.slice(0, 3)
    return firstThreeQuestions
  }, [persona.suggested_questions])

  const randomizeSuggestionQuestions = useCallback(() => {
    setSuggestedQuestions(getRandomSuggestionQuestions())
  }, [getRandomSuggestionQuestions])

  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([])
  const [isClearingChat, setIsClearingChat] = useState<boolean>(false)
  const [latestImages, setLatestImages] = useState<string[]>()
  const initialMessages = useMemo(() => {
    return history.map((message) => ({
      id: String(message.id),
      content: message.content,
      context: message.context,
      role: message.role,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createdAt: new Date(message.created_at),
      is_liked: message.is_liked,
    }))
  }, [history]) as Message[]

  const now = new Date()
  const localTimeString = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString()

  const {
    data,
    input,
    setInput,
    messages,
    isLoading: isPersonaTyping,
    stop,
    handleSubmit,
    handleInputChange,
    append,
    setMessages,
  } = useChat({
    initialMessages,
    body: {
      date: localTimeString,
    },
    onFinish() {
      setLatestImages(undefined)
    },
    onError(error) {
      setError({
        // TODO: This should be handled
        friendlyError: 'Uh oh, something went wrong!',
        error,
      })
    },
    api: `/api/chat/${persona.id}/web`,
  })

  const chatHistory = useMemo(() => {
    const _chatHistory = [...chatHistoryRef.current] as HistoryMessage[]
    if (messages.length === 0) return _chatHistory

    const like_id = data && data.length > 0 ? (data[data.length - 1]! as { like_id: string }).like_id : null
    const memory =
      data && data.length > 0 ? (data[data.length - 1]! as { like_id: string; memory: string }).memory : null
    const memoryId =
      data && data.length > 0
        ? (
            data[data.length - 1]! as {
              like_id: string
              memory: string
              memoryId: string
            }
          ).memoryId
        : null

    const lastMessage = messages[messages.length - 1] as Message & {
      memory: string
      context?: string
    }

    const name = lastMessage.role === 'assistant' ? persona.name : username
    let profileUrl = ''

    if (lastMessage.role === 'assistant') {
      profileUrl = persona.profile_image!
    } else if (user) {
      profileUrl = user.profile_image || ''
    }

    const messageToHistory: HistoryMessage = {
      id: lastMessage.id,
      name,
      role: lastMessage.role,
      profileUrl: profileUrl,
      voiceEnabled: lastMessage.role === 'assistant' && replicaVoiceEnabled,
      content: lastMessage.content,
      context: lastMessage.context,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createdAt: new Date(lastMessage.createdAt),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      is_liked: lastMessage.is_liked,
      images: latestImages,
      like_id,
      memory: {
        // ChatMessage is expecting an object with some more properties, is this correct?
        memory,
        id: memoryId,
        liked: null,
        pair_message: {
          content: messages.length > 1 ? messages[messages.length - 2].content : '',
        },
      },
    }

    if (_chatHistory.length === 0 || String(_chatHistory[_chatHistory.length - 1].id) !== messageToHistory.id) {
      if (!_chatHistory.some((chat) => String(chat.id) === messageToHistory.id)) {
        // this prevents exisiting messages which were returned before an error being instered into the chat history for a second time if the last message doesn't doesn't update after a failed api response
        _chatHistory.push(messageToHistory)
      }
    } else {
      _chatHistory[_chatHistory.length - 1] = messageToHistory
    }

    chatHistoryRef.current = _chatHistory

    return _chatHistory
  }, [
    messages,
    data,
    user,
    username,
    persona.name,
    persona.elevenlabsId,
    persona.voice_manager,
    persona.el_custom_voice_id,
    persona.profile_image,
    persona.id,
    latestImages,
  ])

  const lastMessageRole = chatHistory[chatHistory.length - 1]?.role || 'assistant'

  const id = useId()
  const isNewChat = chatHistory.length === 0
  const anonymousTierExpired = isAnonymousTierExpired(session, chatHistory.length, ignoreSubscriptions, embed)

  const freeTierExpired = messageCap && !!session && chatHistory.length >= SIGNED_IN_MAX_FREE_CHAT_MESSAGES

  // const freePremiumTierExpired = messageCap && chatHistory.length > 1

  const hasSuggestedQuestions = !!persona.suggested_questions?.length
  const isAuthenticated = !!session

  const sendSuggestion = useCallback(
    async (content: string) => append({ id: id, content: content, role: 'user', createdAt: new Date() }),
    [append, id],
  )

  const clearChat = async () => {
    if (!history) return

    try {
      setIsClearingChat(true)

      if (user) {
        const response = await fetch('/api/chat/clear', {
          method: 'POST',
          body: JSON.stringify({
            personaId: persona.id,
          }),
        })

        const res = await response.json()

        if (!response.ok) {
          throw new Error(res.message)
        }
      }

      setLatestImages(undefined)
      chatHistoryRef.current = []
      setMessages([])
      resetError()
    } catch (error) {
      setError({
        error,
        friendlyError: 'Something went wrong clearing chat.',
      })
    } finally {
      setIsClearingChat(false)
    }
  }

  useEffect(() => {
    randomizeSuggestionQuestions()
  }, [randomizeSuggestionQuestions])

  const { data: interactiveAvatarEnabled = false } = useQuery({
    queryKey: ['interactive-avatar-enabled', persona.id],
    queryFn: () => getIsReplicaInteractiveAvatarEnabledServerAction(persona.id),
  })

  const sendMessageDisabled = (session && freeTierExpired) || anonymousTierExpired
  // const lastMessage = messages[messages.length - 1]
  // const personaText = lastMessage?.role === 'assistant' ? lastMessage.content || '' : ''

  const { scrollLine, scrollToBottom } = useChatScrollAnchor({
    trackVisibility: isPersonaTyping,
    lastMessage: chatHistory[chatHistory.length - 1]?.content,
    hasMessages: !isNewChat,
  })

  useEffect(() => {
    if (lastMessageRole === 'user') scrollToBottom()
  }, [lastMessageRole])

  return (
    <div>
      {/* {(freePremiumTierExpired || freeTierExpired) && (
        <ModalArrow
          isUser={Boolean(user)}
          text={user ? 'You need premium plan to use this Persona' : 'Sign in to get access on Premium Replicas'}
        />
      )} */}

      <div className="flex flex-col gap-3 pb-[90px] pt-4 md:pt-10 relative">
        {hasSuggestedQuestions && (
          <ChatSuggestions
            className="mt-6"
            isNewChat={isNewChat}
            suggestedQuestions={suggestedQuestions}
            getSuggestionQuestions={randomizeSuggestionQuestions}
            setSuggestedQuestion={sendSuggestion}
            persona={persona.id}
          />
        )}

        {isClearingChat && (
          <LoadingState
            text="Deleting..."
            className="fixed top-1/2 left-1/2 z-40 px-6 py-1 rounded-sm border backdrop-blur-md backdrop-saturate-200 transform -translate-x-1/2 -translate-y-1/2 w-fit border-black/5 bg-background/80 text-gray-800/60"
          />
        )}

        {!isAuthenticated && messages.length > 0 && !error && !skipAuth && (
          <Alert
            heading="Save Your Chat History"
            type="info"
            actionBtn={{
              text: 'Continue',
              handleActionBtn: () => signIn(),
            }}
          >
            <div className="flex flex-row justify-between items-center w-full">
              <p>Sign in or create an account to preserve your chat history for future access.</p>
            </div>
          </Alert>
        )}

        <ChatHistory
          favourited={favourited}
          messages={chatHistory}
          isPersonaTyping={isPersonaTyping}
          anonymousTierExpired={anonymousTierExpired}
          freeTierExpired={freeTierExpired}
          personaId={persona.id}
          messageCap={messageCap}
          model={persona.model}
          isOwner={persona.owner_uuid === user?.id}
          embed={embed}
          personaUuid={persona.uuid}
        />
        {anonymousTierExpired && <FreeTierExpired />}
        {freeTierExpired && <UserFreeTierExpired />}
        <ChatError />

        {scrollLine}
      </div>

      <div
        className={twMerge(
          'fixed inset-x-0 bottom-[-1px] z-20 mx-auto w-full max-w-4xl bg-background/80 px-3 pb-8',
          className,
        )}
      >
        <PersonaTypingIndicator name={persona.name} isTyping={isPersonaTyping} />

        <ChatInput
          interactiveAvatarEnabled={interactiveAvatarEnabled}
          interactiveAvatarId={persona.hg_custom_avatar_id}
          inputPrompt={inputPrompt}
          input={input}
          setInput={setInput}
          handleInputChange={handleInputChange}
          handleSubmit={(e, data, inputImage) => {
            trackEvent('MESSAGE_SENT', { avatarId: persona.id })
            if (inputImage) {
              setLatestImages(structureImageMessages(inputImage, false))
            }
            resetError()
            handleSubmit(e, data)
          }}
          isDisabled={sendMessageDisabled}
          isPersonaTyping={isPersonaTyping}
          stop={stop}
          personaName={persona.name}
          clearChat={clearChat}
          isNewChat={isNewChat}
          appendMessage={append}
          personaId={persona.id}
          embed={embed}
          classNameInput={classNameInput}
          primaryBgColor={primaryBgColor}
        />
      </div>
    </div>
  )
}

export default Chat
